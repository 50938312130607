@import 'src/utils/utils.scss';

.tooltip {
  right: 9px;
  display: flex;
  align-items: center;
  pointer-events: none;
  flex-shrink: 0;

  @media(min-width: $md) {
    right: unset;
  }

  &__button {
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    border: 0;
    background: 0;
    outline: none;
    cursor: pointer;
    pointer-events: all;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  &__wrapper {
    display: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}

.tippy-box {
  padding-top: 10px;
  padding-right: 15px;
  padding-bottom: 10px;
  padding-left: 15px;
  border: 0;
  border-radius: 10px;
  background-color: $t-60;
  box-shadow: 0 5px 20px rgba($color: #000d39, $alpha: 10%);
  pointer-events: all;
}

.tippy-content {
  @include Text-16-reg;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $Main-2;

  p {
    margin: 0;
    padding: 0;

    &:not(:first-of-type) {
      margin-top: 5px;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    gap: 5px;

    &:not(:first-child) {
      margin-top: 10px;
    }
  }

  li {
    display: flex;
    gap: 10px;

    &:before {
      content: '—';
    }
  }
}